import React,{useEffect} from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import CardCarousel from "./CardCarousel";
import { useSelector,useDispatch } from "react-redux";
import { fetchProducts } from "../redux/slices/counterSlice";


function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#fd6713", borderRadius:"100px" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#fd6713", borderRadius:"100px"  }}
      onClick={onClick}
    />
  );
}

function BestSellerCarousel() {
  const products = useSelector((state) => state.counter.products)
  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(fetchProducts())
  },[])


  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    initialSlide: 0,
    prevArrow: <SamplePrevArrow/>,
    nextArrow: <SampleNextArrow/>,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div className="slider-container">
      <Slider {...settings}>
        {
          products.map((item,key) => {
            
            let changePriceDot = item.product_price.toLocaleString();
            
            
            return(
              <div key={key}>
                <CardCarousel id={item.id} product_name={item.product_name} product_price={changePriceDot}/>
              </div>
            )
          })
        }
      </Slider>
    </div>
  );
}

const BestSeller = () => {
  return (
    <div className="wrapper-carousel">
      <div className="wrapper-bestseller">
        <div className="wrapper-bestseller-1">
          <h3>Bestsellers</h3>
        </div>
        <div className="wrapper-bestseller-2">
          <a>
            <span>Tất cả sản phẩm</span>
          </a>
        </div>
        <div className="wrapper-bestseller-3">
          <BestSellerCarousel />
        </div>
      </div>
      <div className="container-bestseller"></div>
    </div>
  );
};

export default BestSeller;
