import React from 'react';

const Vccb = () => {
    return (
        <div className='wrapper-vccb'>
            <div className='wrapper-vccb-text'>
                <h2>VUI CHƠI CÙNG BÉ</h2>
            </div>
            <div className='wrapper-img-text-shop'>
                <div className='wrapper-menu1'>
                    <div className='wrapper-img'>

                    </div>
                    <div className='wrapper-text'>
                        <p>VỆ SĨ GIẤC MƠ</p>
                    </div>
                    <div className='wrapper-shop'>
                        <p>SHOP NOW A</p>
                    </div>
                </div>
                <div className='wrapper-menu1'>
                    <div className='wrapper-img'>

                    </div>
                    <div className='wrapper-text'>
                        <p>NGÓN TAY TÍ HON</p>
                    </div>
                    <div className='wrapper-shop'>
                        <p>SHOP NOW B</p>
                    </div>
                </div>
                <div className='wrapper-menu1'>
                    <div className='wrapper-img'>

                    </div>
                    <div className='wrapper-text'>
                        <p>GẬM NHẮM VUI VẺ </p>
                    </div>
                    <div className='wrapper-shop'>
                        <p>SHOP NOW C</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Vccb;
