import React, { useState } from "react";

const FAQs = () => {
  const [arrow, setArrow] = useState({
    faq1: false,
    faq2: false,
    faq3: false,
    faq4: false,
    faq5: false,
    faq6: false,
  });

  const [checkFAQ, setCheckFAQ] = useState(false);

  const handleArrowClick = (id) => {
    switch (id) {
      case "faq1":
        if (arrow.faq1 == false) {
          let { faq1, ...rest } = arrow;
          setArrow({
            faq1: true,
            ...rest,
          });
        } else {
          setCheckFAQ(true);
          let { faq1, ...rest } = arrow;
          setArrow({
            faq1: false,
            ...rest,
          });
        }

        break;
      case "faq2":
        if (arrow.faq2 == false) {
          let { faq2, ...rest } = arrow;
          setArrow({
            faq2: true,
            ...rest,
          });
        } else {
          let { faq2, ...rest } = arrow;
          setArrow({
            faq2: false,
            ...rest,
          });
        }
        break;
      case "faq3":
        if (arrow.faq3 == false) {
          let { faq3, ...rest } = arrow;
          setArrow({
            faq3: true,
            ...rest,
          });
        } else {
          let { faq3, ...rest } = arrow;
          setArrow({
            faq3: false,
            ...rest,
          });
        }
        break;
      case "faq4":
        if (arrow.faq4 == false) {
          let { faq4, ...rest } = arrow;
          setArrow({
            faq4: true,
            ...rest,
          });
        } else {
          let { faq4, ...rest } = arrow;
          setArrow({
            faq4: false,
            ...rest,
          });
        }
        break;
      case "faq5":
        if (arrow.faq5 == false) {
          let { faq5, ...rest } = arrow;
          setArrow({
            faq5: true,
            ...rest,
          });
        } else {
          let { faq5, ...rest } = arrow;
          setArrow({
            faq5: false,
            ...rest,
          });
        }
        break;
      case "faq6":
        if (arrow.faq6 == false) {
          let { faq6, ...rest } = arrow;
          setArrow({
            faq6: true,
            ...rest,
          });
        } else {
          let { faq6, ...rest } = arrow;
          setArrow({
            faq6: false,
            ...rest,
          });
        }
        break;

      default:
        break;
    }
  };

  return (
    <div className="wrapper-faqs">
      <div className="container-faqs">
        <div className="container-ship">
          <h3 className="container-text">FAQs</h3>
          <h3 className="text">VẬN CHUYỂN VÀ BẢO HÀNH</h3>
          <div className="wrapper-question-answer">
            <div className="wrapper-question">
              <div className="question-text">Phí vận chuyển là bao nhiêu?</div>
              <button
                className={
                  arrow.faq1
                    ? "question-svg open-faq"
                    : checkFAQ
                    ? "question-svg close-faq"
                    : "question-svg"
                }
                onClick={() => handleArrowClick("faq1")}
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="-4.5 0 20 20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>arrow_right [#c6c3c3]</title>{" "}
                    <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      {" "}
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-425.000000, -6679.000000)"
                        fill="#cccccc"
                      >
                        {" "}
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          {" "}
                          <path
                            d="M370.39,6519 L369,6520.406 L377.261,6529.013 L376.38,6529.931 L376.385,6529.926 L369.045,6537.573 L370.414,6539 C372.443,6536.887 378.107,6530.986 380,6529.013 C378.594,6527.547 379.965,6528.976 370.39,6519"
                            id="arrow_right-[#c6c3c3]"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </button>
            </div>
            {arrow.faq1 ? (
              <div className="answer-text">
                Bên mình áp dụng miễn phí vận chuyển cho mọi đơn hàng trên 50k.
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="wrapper-question-answer">
            <div className="wrapper-question">
              <div className="question-text">Thời gian giao hàng bao lâu?</div>
              <button
                className={
                  arrow.faq2
                    ? "question-svg open-faq"
                    : checkFAQ
                    ? "question-svg close-faq"
                    : "question-svg"
                }
                onClick={() => handleArrowClick("faq2")}
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="-4.5 0 20 20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>arrow_right [#c6c3c3]</title>{" "}
                    <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      {" "}
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-425.000000, -6679.000000)"
                        fill="#cccccc"
                      >
                        {" "}
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          {" "}
                          <path
                            d="M370.39,6519 L369,6520.406 L377.261,6529.013 L376.38,6529.931 L376.385,6529.926 L369.045,6537.573 L370.414,6539 C372.443,6536.887 378.107,6530.986 380,6529.013 C378.594,6527.547 379.965,6528.976 370.39,6519"
                            id="arrow_right-[#c6c3c3]"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </button>
            </div>
            {arrow.faq2 ? (
              <div className="answer-text">
                Tùy vào tình hình vận chuyển của đơn vị vận chuyển, nhưng trung
                bình các 1-2 ngày cho các đơn hàng trong nội thành hcm, 3 ngày
                cho các tỉnh miền nam và 5-6 ngày dành cho khu vực miền trung và
                miền bắc
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="wrapper-question-answer-border-none">
            <div className="wrapper-question">
              <div className="question-text">
                Sản phẩm giao đến bị hư hại thì phải làm sao?
              </div>
              <button
                className={
                  arrow.faq3
                    ? "question-svg open-faq"
                    : checkFAQ
                    ? "question-svg close-faq"
                    : "question-svg"
                }
                onClick={() => handleArrowClick("faq3")}
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="-4.5 0 20 20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>arrow_right [#c6c3c3]</title>{" "}
                    <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      {" "}
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-425.000000, -6679.000000)"
                        fill="#cccccc"
                      >
                        {" "}
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          {" "}
                          <path
                            d="M370.39,6519 L369,6520.406 L377.261,6529.013 L376.38,6529.931 L376.385,6529.926 L369.045,6537.573 L370.414,6539 C372.443,6536.887 378.107,6530.986 380,6529.013 C378.594,6527.547 379.965,6528.976 370.39,6519"
                            id="arrow_right-[#c6c3c3]"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </button>
            </div>
            {arrow.faq3 ? (
              <div className="answer-text">
                Với những đơn hàng sản phẩm bị hư hại do quá trình vận chuyển,
                bên mình sẽ hỗ trợ hoàn lại hàng hoặc đổi 1 sản phẩm tương tự.
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="container-san-pham">
          <h3>SẢN PHẨM</h3>
          <div className="wrapper-question-answer">
            <div className="wrapper-question">
              <div className="question-text">Có phải 100% handmade ?</div>
              <button
                className={
                  arrow.faq4
                    ? "question-svg open-faq"
                    : checkFAQ
                    ? "question-svg close-faq"
                    : "question-svg"
                }
                onClick={() => handleArrowClick("faq4")}
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="-4.5 0 20 20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>arrow_right [#c6c3c3]</title>{" "}
                    <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      {" "}
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-425.000000, -6679.000000)"
                        fill="#cccccc"
                      >
                        {" "}
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          {" "}
                          <path
                            d="M370.39,6519 L369,6520.406 L377.261,6529.013 L376.38,6529.931 L376.385,6529.926 L369.045,6537.573 L370.414,6539 C372.443,6536.887 378.107,6530.986 380,6529.013 C378.594,6527.547 379.965,6528.976 370.39,6519"
                            id="arrow_right-[#c6c3c3]"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </button>
            </div>
            {arrow.faq4 ? (
              <div className="answer-text">
                Bên mình áp dụng miễn phí vận chuyển cho mọi đơn hàng trên 50k.
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="wrapper-question-answer">
            <div className="wrapper-question">
              <div className="question-text">
                Sản phẩm có an toàn cho bé dưới 12 tháng tuổi ?
              </div>
              <button
                className={
                  arrow.faq5
                    ? "question-svg open-faq"
                    : checkFAQ
                    ? "question-svg close-faq"
                    : "question-svg"
                }
                onClick={() => handleArrowClick("faq5")}
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="-4.5 0 20 20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>arrow_right [#c6c3c3]</title>{" "}
                    <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      {" "}
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-425.000000, -6679.000000)"
                        fill="#cccccc"
                      >
                        {" "}
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          {" "}
                          <path
                            d="M370.39,6519 L369,6520.406 L377.261,6529.013 L376.38,6529.931 L376.385,6529.926 L369.045,6537.573 L370.414,6539 C372.443,6536.887 378.107,6530.986 380,6529.013 C378.594,6527.547 379.965,6528.976 370.39,6519"
                            id="arrow_right-[#c6c3c3]"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </button>
            </div>
            {arrow.faq5 ? (
              <div className="answer-text">
                Sản phẩm bên mình rất an toàn cho bé dưới 12 tháng tuổi.
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="wrapper-question-answer-border-none">
            <div className="wrapper-question">
              <div className="question-text">
                Làm thế nào để vệ sinh đồ chơi khi bé chơi xong?
              </div>
              <button
                className={
                  arrow.faq6
                    ? "question-svg open-faq"
                    : checkFAQ
                    ? "question-svg close-faq"
                    : "question-svg"
                }
                onClick={() => handleArrowClick("faq6")}
              >
                <svg
                  width="20px"
                  height="20px"
                  viewBox="-4.5 0 20 20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#000000"
                >
                  <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>arrow_right [#c6c3c3]</title>{" "}
                    <desc>Created with Sketch.</desc> <defs> </defs>{" "}
                    <g
                      id="Page-1"
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      {" "}
                      <g
                        id="Dribbble-Light-Preview"
                        transform="translate(-425.000000, -6679.000000)"
                        fill="#cccccc"
                      >
                        {" "}
                        <g
                          id="icons"
                          transform="translate(56.000000, 160.000000)"
                        >
                          {" "}
                          <path
                            d="M370.39,6519 L369,6520.406 L377.261,6529.013 L376.38,6529.931 L376.385,6529.926 L369.045,6537.573 L370.414,6539 C372.443,6536.887 378.107,6530.986 380,6529.013 C378.594,6527.547 379.965,6528.976 370.39,6519"
                            id="arrow_right-[#c6c3c3]"
                          >
                            {" "}
                          </path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </button>
            </div>
            {arrow.faq6 ? (
              <div className="answer-text">
                Chỉ cần cho sản phẩm vào máy giặt là được.
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div className="wrapper-span">
          <a>
            <span>
              Xem thêm về chính sách vận chuyển và bảo hành của Wobble &Play.
            </span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
