import React from 'react';

const Banner = () => {
    return (
        <div className='wrapper-banner'>
            <p>Banner khuyến mãi </p>
        </div>
    );
}

export default Banner;
