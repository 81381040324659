import React from 'react';

const ShopNow3 = () => {
    return (
        <div className='wrapper-shopnow3'> 
            <div className='wrapper-notes-shopnow3'>
                <div className='wrapper-notes'>
                    <div className='wrapper-text'>
                        <p>Khám phá BST mới</p>
                    </div>
                    <div className='wrapper-shop3'>
                        <p>SHOP NOW 3</p>
                    </div>
                </div>
            </div>
            <div className='wrapper-freeship'>
                <p>Miễn phí vận chuyển cho tất cả đơn hàng trên 99k. <span>Shop now</span></p>
            </div>          
        </div>
    );
}

export default ShopNow3;
