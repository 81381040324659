import React from 'react';

const BodyIns = () => {
    return (
        <div className='wrapper-bodyins'>
            <div className='wrapper-body'>
                <div className='wrapper-body-text'>
                    <div className='wrapper-img-text'>
                        <div className='wrapper-img'>
                            
                        </div>
                        <div className='text'>
                            <h3>Wobble&Play</h3>
                        </div>
                    </div>   
                    <p>Theo dõi chúng tôi trên instagram để cập nhật những sản phẩm mới nhất</p>
                </div>
                <div className='wrapper-body-img'>
                    <div className='body-img'></div>
                    <div className='body-img'></div>
                    <div className='body-img'></div>
                    <div className='body-img'></div>
                </div>
            </div>
        </div>
    );
}

export default BodyIns;
