import React from 'react';


const CardCarousel = (props) => {

    
    return (
        <div className='wrapper-card' style={{width:"280px", height:"380px", display:"flex", flexDirection:"column", margin:"0 auto"}}>
            <div className='wrapper-card-background' style={{width:"280px", height:"280px", backgroundColor:"#fff", }}>
                {/* {props.id} */}
            </div>
            <div className='wrapper-card-text' style={{width:"280px", height:"22px", marginTop:"19px", marginBottom:"24px", color:"#333", fontSize:'20px', overflow:'hidden'}}>
                <p>{props.product_name}</p>
            </div>
            <div className='wrapper-card-shopping' style={{width:"250px", backgroundColor:"#ff3131", color:"#fff", fontSize:"18px", padding:"7px 17px", display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                {props.product_price}
                <p>Thêm vào giỏ hàng</p>
            </div>
        </div>
    );
}

export default CardCarousel;
