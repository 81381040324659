import React from 'react';

const ShopNow4 = () => {
    return (
        <div className='wrapper-shopnow4'>
            <div className='wrapper-shopnow4-background'>
                <div className='container-shopnow4'>
                    <div className='container-text'>
                        <h3 className='text-h3'>SET QUÀ</h3>
                        <h3 className='text'>THÔI NÔI CHO BÉ</h3>
                    </div>
                    <div className='container-p'>
                        <p>Tìm kiếm món quà hoàn hảo cho bé yêu trong ngày thôi nôi?</p>
                        <p>Hãy khám phá set quà thôi nôi độc đáo từ Wobble & Play!</p>   
                    </div>
                    
                    <div className='container-button'>
                        <button>SHOP NOW 4</button>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default ShopNow4;
