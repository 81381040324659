
import { initializeApp } from "firebase/app";

import {getFirestore,setDoc,doc, getDocs, collection} from "firebase/firestore";


const firebaseConfig = {
  apiKey: process.env.REACT_FIREBASE_APIKEY,
  authDomain: process.env.REACT_FIREBASE_AUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASE_APPID
};

// Initialize Firebase
 

const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);

// function

export const sendProduct = (productId,productName,productPrice) => {
  setDoc(doc(firestore,"products",productId),{
    id: productId,
    product_name: productName,
    product_price: productPrice
})
}

export const getProducts = async () => {
    const querySnapShot = await getDocs(collection(firestore,"products"));
    const data = [];
    querySnapShot.forEach((item)=>{
      data.push(item.data())
    })
    return data;
}

