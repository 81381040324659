import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {buttonScribeOpenHandleClick,buttonScribeCloseHandleClick } from '../redux/slices/counterSlice'

const Subscribe = () => {
    const [emailChange, setEmailChange] = useState("")
    const [checkEmail, setCheckEmail] = useState(true)
    const dispatch = useDispatch()
    const handleOnClick = () => {
        if(!checkEmail||emailChange.length===0){
            alert("ban da nhap sai email, ban can nhap lai email")
        }else{
            dispatch(buttonScribeOpenHandleClick());
            setTimeout(()=>{
                dispatch(buttonScribeCloseHandleClick())
            },3000)
        }
    }

    const emailValidation = (email) => {
            const atSymbol = email.indexOf('@');
            const dot = email.lastIndexOf('.'); 
            if(atSymbol > 0 && dot > atSymbol){
                setCheckEmail(true)
            }else{
                setCheckEmail(false)
            } 
        }
    
        const handleOnChange = (e) => {
            emailValidation(e.target.value)
            setEmailChange(e.target.value);
        }


    return (
        <div className='wrapper-subscribe'>
            <div className='wrapper-text'>
                <h3>SUBSCRIBE</h3>
                <p>Hãy tham gia cộng đồng Wobble & Play ngay hôm nay để cùng bé yêu khám phá thế giới đầy ắp điều kỳ diệu!</p>
            </div>
            <div className='wrapper-input'>
                <div className='wrapper-input-email'>
                    <input type="email" name='emails' placeholder='Email....' required onChange={(e)=>handleOnChange(e)}/>
                </div>
                <div className='wrapper-text-dki'>
                    <button onClick={handleOnClick}>Đăng kí</button>              
                </div>
            </div>
        </div>
    );
}

export default Subscribe;
