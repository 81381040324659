import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {buttonScribeCloseHandleClick } from '../redux/slices/counterSlice'

const ButtonSubscribe = () => {
    const dispatch = useDispatch();

    const handleClick = () => {
        dispatch(buttonScribeCloseHandleClick());
    }

    return (
        <div  className='wrapper-dang-ky' onClick={handleClick}>
            <div className='container-dang-ky'>
                <h3>THANK YOU</h3>
                <p>Chào mừng bạn đến với Wobble & Play. Chúng tôi vô cùng vui mừng khi có bạn ở đây để cùng chúng tôi mang đến một thế giới đầy màu sắc và diệu kỳ dành cho bé. Hãy sẵn sàng cho hành trình đầy thú vị cùng chúng tôi !</p>
            </div>
        </div>
    );
}

export default ButtonSubscribe;
