import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getProducts } from '../../firebase'
import axios from 'axios'

const initialState = {
  value: 0,
  status:"idle",
  products: [],
  error:"",
  buttonScribe:false,
  data: [],
}



export const fetchData = createAsyncThunk("get/fetchData", async () => {
  const response = await axios.get('');
  return response.data;
})

export const fetchProducts = createAsyncThunk("get/fetchProducts", async () => {
  const response = await getProducts();
  return response;
})

export const dataSlice = createSlice({
    name: 'counter',
    initialState,
    reducers: {
      buttonScribeOpenHandleClick(state) {
        state.buttonScribe=true;
      },
      buttonScribeCloseHandleClick(state) {
        state.buttonScribe=false;
      }
    },
    extraReducers(buider){
      buider
      .addCase(fetchData.pending,(state,action)=>{
        state.status="loading";
      })
      .addCase(fetchData.fulfilled,(state,action)=>{
        state.status="succeeded";
        state.data = action.payload;
      })
      .addCase(fetchData.rejected,(state,action)=>{
        state.status="failed";
        state.error = action.error;
      })
    },
    extraReducers(buider){
      buider
      .addCase(fetchProducts.pending,(state,action)=>{
        state.status="loading";
      })
      .addCase(fetchProducts.fulfilled,(state,action)=>{
        state.status="succeeded";
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected,(state,action)=>{
        state.status="failed";
        state.error = action.error;
      })
    }
  })
  



  // Action creators are generated for each case reducer function
  export const { buttonScribeOpenHandleClick, buttonScribeCloseHandleClick } = dataSlice.actions
  
  export default dataSlice.reducer