
import { useSelector, useDispatch } from 'react-redux';
import {buttonScribeHandleClick } from './redux/slices/counterSlice'
import { fetchProducts} from './redux/slices/counterSlice';
import './scss/wobble.css';
import ButtonSubscribe from './components/ButtonSubscribe';
import Navbar from './components/Navbar';
import Search from './components/Search';
import Notes from './components/Notes';
import Vccb from './components/Vccb';
import Subscribe from './components/Subscribe';
import ShopNow3 from './components/ShopNow3';
import BestSeller from './components/BestSeller';
import ShopNow4 from './components/ShopNow4';
import Banner from './components/Banner';
import BodyIns from './components/BodyIns';
import FAQs from './components/FAQs';
import Footer from './components/Footer'
import { useEffect } from 'react';

function App() {
  
  const scribe = useSelector((state) => state.counter.buttonScribe)
  
  console.log(process.env.REACT_APP_FIREBASE_PROJECTID);
  
  return (

    <div className="App"> 
      {scribe ? <ButtonSubscribe/> : <></>}
      
      <Navbar/>
      {/* <Search/> */}
      <Notes/>
      <Vccb/>

      <Subscribe />


      <ShopNow3/>
      <BestSeller/>
      <ShopNow4/>
      <Banner/>
      <BodyIns/>
      <FAQs/>
      <Footer/>
    </div>
  );
}

export default App;
