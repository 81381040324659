import React from 'react';

const Card = (props) => {
    
    return (
        <div className='wrapper-cardlist' style={{width: "300px", height: "58px", display: "flex", flexDirection:"row", marginTop:"18px"}}>
            <div className='wrapper-cardlist-1' style={{width: "65px", height: "58px",  backgroundColor:"#edefef"}}>
               
            </div>
            <div className='wrapper-cardlist-2' style={{width:"235px", height:"58px", display:"flex", flexDirection:"column", marginLeft:"10px"}}>
                <div className='wrapper-cardlist-name'style={{width: "240px", height:"22px", color:"#fff", fontSize:"14px"}}>
                    {props.product_name}
                </div>
                <div className='wrapper-cardlist-price' style={{width: "60px", height: "26px",border: "1px solid #fff", borderRadius:"100px", backgroundColor:"#fffafa", color:"#b61b1b", fontSize:"12px", display:"flex", alignItems:"center", justifyContent:"center", fontWeight:"600"}}>
                    {props.product_price}
                </div>
            </div>
        </div>
    );
}

export default Card;
