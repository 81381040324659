import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Card from "./Card";



const Search = () => {
  const products = useSelector((state) => state.counter.products);
  const [search, setSearch] = useState(false);
  const [searchChange, setSearchChange] = useState("");
  const [searchList, setSearchList] = useState({});

  const handleSearchClick = () => {
    if (search === false) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  };

  const handleSearchChange = (e) => {
    setSearchChange(e.target.value);
  };

  useEffect(()=>{
    console.log(searchList);
    
  },[searchList])

  useEffect(() => {
    // console.log(searchChange);
    if (searchChange !== "") {
      setSearchList(searchProducts(searchChange, products));
    }else{
        setSearchList([]);
    }
  }, [searchChange]);

  const searchProducts = (searchValue, products) => {
    let result = [];

    products.map((product) => {
      if (product.product_name.includes(searchValue)) {
        result.push(product);
      }
    });

    return result;
  };

  useEffect(() => {
    // console.log(products);
  }, [products]);

  useEffect(() => {
    //    console.log(search);
  }, [search]);

  return (
    <div className="header-icon-search">
      <div
        className={
          search
            ? "container-border-search display-flex"
            : "container-border-search display-none"
        }
      >
        <input
          className="input-search"
          type="text"
          placeholder="Tìm kiếm ..."
          onChange={(e) => handleSearchChange(e)}
        />
        {searchList.length > 0 ? (
          <div className="wrapper-search-position">
            <div className="search-position">
              {searchList.slice(0,3).map((item, key)=>{


                return <Card key={key} product_name={item.product_name} product_price={item.product_price} />
              })}

            
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
      <button className="search-icon" onClick={handleSearchClick}>
        <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
          <g
            id="SVGRepo_tracerCarrier"
            strokeLinecap="round"
            strokeLinejoin="round"
          ></g>
          <g id="SVGRepo_iconCarrier">
            {" "}
            <path
              d="M15.7955 15.8111L21 21M18 10.5C18 14.6421 14.6421 18 10.5 18C6.35786 18 3 14.6421 3 10.5C3 6.35786 6.35786 3 10.5 3C14.6421 3 18 6.35786 18 10.5Z"
              stroke="#fff"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>{" "}
          </g>
        </svg>
      </button>
    </div>
  );
};

export default Search;
