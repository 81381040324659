import React from 'react';

const Notes = () => {
    return (
        <div className='wrapper-notes'>
            <div className='wrapper-text-notes'>
                <h2>Wobble & Play</h2>
                <p>Đồng hành cùng bé yêu trong hành trình khám phá thế giới!</p>
            </div>
            <div className='wrapper-text-thong-diep'>
                <p> 
                    for participating in Kraft-in association's House of Carpentry
                    courses held at the Kraft-in office and showing
                    exemplary performance in the courses taken.
                </p>
            </div>
        </div>
    );
}

export default Notes;
