import React from 'react';

const Fotter = () => {
    return (
        <div className='wrapper-footer'>
            <div className='container-footer'>
                <div className='container-text'>
                    <h3>Wobble & Play</h3>
                    <div className='hr'>

                    </div>
                    <p> Đồng hành cùng bé yêu trong hành trình khám phá thế giới!</p>
                </div>
                <div className='container-lien-he-faqs'>
                    <div className='container-lien-he'>
                        <h3>Liên hệ.</h3>
                        <p>Email: wobbleandplay@gmail.com</p>
                        <p>SĐT:  +84 703953194</p>
                        <p>Chính sách vận chuyển và bảo hành.</p>
                    </div>
                    <div className='container-img-zalo'>
                        <div className='container-img'>

                        </div>
                        <p>Mở ra danh sách câu hỏi FAQs hoặc liên kết qua zalo</p>
                    </div>
                </div>
            </div>
            <div className='container-hr'>

            </div>
        </div>
    );
}

export default Fotter;
